.box {
    border-radius: 15px;
    padding: 2em;
    margin-bottom: 2em;
    box-shadow: 0 1rem 2rem rgba(0,0,0,.175)!important;
}
#googlediv > button {
    width:100%;
  
}
#fbdiv > span > button {
    width:100%;
    padding: 0;
    display: inline-flex;
    text-transform: unset;

    padding-top: 8px;
}
#fbdiv > span > button > i{
    margin-top: -9px;
    padding: 13px 14px;
    border-radius: 2px;
    background: white;
    font-weight: bold;
    color: #4c69ba;
}
.choose,.choose1{
    display:none;
}
.testimonial_slider_text{
   display: flex; 
}
.testimonial_slider_text:hover > .choose, .testimonial_slider_text:hover > .choose1{
    position: absolute;
    left: 35%;
    display: initial;
    background: #000000eb;
    align-self: center;
    border-radius: 5px;
    color: #fff;
    cursor:"pointer";
    padding: 1%;
}
